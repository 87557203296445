/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'shield-x': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M5.338 1.59a61 61 0 00-2.837.856.48.48 0 00-.328.39c-.554 4.157.726 7.19 2.253 9.188a10.7 10.7 0 002.287 2.233c.346.244.652.42.893.533q.18.085.293.118a1 1 0 00.101.025 1 1 0 00.1-.025q.114-.034.294-.118c.24-.113.547-.29.893-.533a10.7 10.7 0 002.287-2.233c1.527-1.997 2.807-5.031 2.253-9.188a.48.48 0 00-.328-.39c-.651-.213-1.75-.56-2.837-.855C9.552 1.29 8.531 1.067 8 1.067c-.53 0-1.552.223-2.662.524zM5.072.56C6.157.265 7.31 0 8 0s1.843.265 2.928.56c1.11.3 2.229.655 2.887.87a1.54 1.54 0 011.044 1.262c.596 4.477-.787 7.795-2.465 9.99a11.8 11.8 0 01-2.517 2.453 7 7 0 01-1.048.625c-.28.132-.581.24-.829.24s-.548-.108-.829-.24a7 7 0 01-1.048-.625 11.8 11.8 0 01-2.517-2.453C1.928 10.487.545 7.169 1.141 2.692A1.54 1.54 0 012.185 1.43 63 63 0 015.072.56"/><path pid="1" d="M6.146 5.146a.5.5 0 01.708 0L8 6.293l1.146-1.147a.5.5 0 11.708.708L8.707 7l1.147 1.146a.5.5 0 01-.708.708L8 7.707 6.854 8.854a.5.5 0 11-.708-.708L7.293 7 6.146 5.854a.5.5 0 010-.708"/>',
    },
});
